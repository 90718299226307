import React from "react"
import { graphql } from "gatsby"
import DefaultLayout from "./default"
import ResearchAreas from "../components/research-areas"

const ResearchTemplate = ({ data }) => {
  return (
    <DefaultLayout data={data}>
      <ResearchAreas />
    </DefaultLayout>
  )
}

export default ResearchTemplate

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        quote
        quoteAuthor
        image {
          childImageSharp {
            fluid(maxWidth: 280) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
